import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './loginPage/App';
import PowerLayout from './components/power-layout/PowerLayout.js'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import './index.css'
import Labelling from './pages/Labelling'
import Login from './loginPage/Login'
import App from './homePage/App'
import AccountDetail from './pages/accountDetailsPage.js'
import Validation from './pages/validation.js'

import { GlobalInfoProvider } from './GlobalInfoContext.js'

const Root = () => {
    return <App />
  }
  
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/labelling",
        element: <PowerLayout />,
        children: [
            { index: true, element: <Labelling /> }, // default
            { path: "/labelling", element: <Login /> }
        ]
    },
    {
        path: "/account",
        element: <AccountDetail />
    },
    {
        path: "/validation",
        element: <Validation />
    }
])

ReactDOM.createRoot(document.getElementById('root')).render(
<React.StrictMode>
    <GlobalInfoProvider>
        <RouterProvider router={router}></RouterProvider>
    </GlobalInfoProvider>
</React.StrictMode>,
)
