import { getRequest, postRequest, getImageBase64 } from "../../lib/request";

export const validateapi = {
    async home() {
        return await getRequest('/');
    },

    async getPage(params) {
        return await getRequest('/animal/validation/queryList', params);
    },

    async getImageNum(params) {
        return await getRequest('/animal/validation/queryList/count', params);
    },

    async save(params) {
        return await postRequest('/animal/validation/validate', params);
    },

    // async getAllLabelledImagesNum(params) {
    //     return await getRequest('/animal/validation/queryLabelledImagesByProject', params);
    // },

    // async getAllImagesNum(params) {
    //     return await getRequest('/animal/validation/queryAllImagesByProject', params);
    // },

    // async getAllLabelledImagesNumByUser(params) {
    //     return await getRequest('/animal/validation/queryHistoryCountByUser', params);
    // },

    // async getLabelledImagesListByUser(params) {
    //     return await getRequest('/animal/validation/queryHistoryCountListByUser', params);
    // },

    // async getAllUsersLabelCount(params) {
    //     return await getRequest('/animal/validation/queryAllUserLabelledNum', params);
    // },
};